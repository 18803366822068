<template>
  <component :is="layout">
    <div v-if="user" class="card shadow text-center p-2 m-2">
        <h3 class="text-dark">Profile</h3>
        <span v-if="message">
          <p class="text-center alert-success">{{ message }}</p>
        </span>
        <span v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <p class="alert-danger" v-for="error in errors" :key="error">
              {{ error }}
            </p>
          </span>
        <div class="form-group">
            <label for="result">Name</label>
            <input type="text" class="form-control" v-model="user.name">
        </div>
        <div class="form-group">
            <label for="result">Email</label>
            <input type="text" class="form-control" v-model="user.email">
        </div>
        <div class="form-group">
            <label for="result">Password</label>
            <input type="password" class="form-control" v-model="password">
        </div>
        <div class="form-group py-4">
            <button @click="updateUser()" class="btn bg-danger btn-danger w-100 success">Update</button>
        </div>
    </div>
  </component>
</template>

<script>
import axiosObject from '../../axiosHandler'
import axiosConfig from '../../axiosConfig'
export default {
  computed:{
      layout()
      {
          return (this.$route.meta.layout)
      }
  },
  data()
  {
      return{
          user:null,
          password:null,
          message:null,
          errors:[]
      }
  },
  created()
  {
    this.fetchUser()
  },
  methods:{
      fetchUser()
      {
          axiosObject.get('/api/client/profile',axiosConfig).then(function(response){
              this.user = response.data.user
          }.bind(this)).catch(function(error){
             alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
          })
      },
      updateUser()
      {
          this.errors = [];
         if (!this.user.name || !this.user.email) {
        this.errors.push("Name and Email are Required.");
        return;
      }

       if (this.password && this.password.length < 8) {
        this.errors.push("Password should be at least 8 letters.");
        return;
      }
         axiosObject.patch('/api/client/profile/'+this.user.id,{
             'user':this.user,
             'password':this.password
         },axiosConfig)
         .then(function(response){
              this.fetchUser()
              this.message = response.data.message
          }.bind(this)).catch(function(error){
             alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
          })
      }
  }
}
</script>

<style>

</style>
